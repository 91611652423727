import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";

const columns = [
  {
    title: "IR #",
    field: "irNumber.value",
    render: (row) => (row.irNumber?.prefix || "") + (row.irNumber?.value || ""),
  },
  {
    title: "Invoice Date",
    field: "invoiceDate",
    render: (row) => formatDateToRead(row?.invoiceDate),
  },
  {
    title: "Template",
    field: "template",
  },
  {
    title: "Customer",
    field: "account.contactName",
  },
  {
    title: "Company",
    field: "account.companyName",
  },
  {
    title: "Balance Amount",
    field: "balanceAmount",
  },
  {
    title: "Balance Fine Gold",
    field: "balanceFineGold",
  },

  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const InvoicesList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.invoices}
      headerTitle="Invoices"
      searchFields={[
        "invoiceType",
        "template",
        "account.contactName",
        "account.companyName",
      ]}
      numberFields={["balanceAmount", "balanceFineGold", "irNumber.value"]}
      dateFields={["invoiceDate"]}
      paginationMeta={{ pageSize: 100 }}
      hideAction={{ delete: true, selection: true, create: true }}
      onRowClick={false}
      options={{
        exportButton: {
          csv: true,
          pdf: true,
        },
        pageSizeOptions: [
          10, 20, 50, 100, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500,
          5000,
        ],
      }}
    />
  );
};

export default InvoicesList;
