import React, { useState, useEffect } from "react";
import NoConnection from "./no-connection.png";
import Reporter from "./reporter.png";
import Emitter from "@ui/Utils/CustomEventEmitter";
import { EventEmitter } from "events";
import { Paper } from "@material-ui/core/index";

const emitter = new EventEmitter();

const InternetConnectionChecker = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      Emitter.emit("alert_success", "online");
    };

    const handleOffline = () => {
      setIsOnline(false);
      Emitter.emit("alert_error", "Check your internet");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <Paper
      className="pulse"
      style={{ ...fullPageStyle, display: isOnline ? "none" : "flex" }}
    >
      <div
        style={{
          ...warningIconStyle,
          display: isOnline ? "none" : "flex",
          userSelect: "none",
        }}
      >
        <img src={NoConnection} alt="No Connection" style={imageStyle} />
        <img src={Reporter} alt="Reporter" style={imageStyle} />
      </div>
    </Paper>
  );
};

const fullPageStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100,
  backgroundColor: "rgba(255, 255, 255, 0.9)", // Slightly opaque background
};

const warningIconStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "20px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
};

const imageStyle = {
  height: "120px",
  width: "120px",
  marginBottom: "10px",
};

export default InternetConnectionChecker;
