import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";

const columns = [
  {
    title: "List",
    render: (row) => {
      return (
        <ul>
          {Object.keys(row.list || {}).map((key, index) => (
            <li key={index}>
              {`${row.list[key]?.label} ( ${(
                row.list[key]?.percent * 100
              ).toFixed(2)} % )`}
            </li>
          ))}
        </ul>
      );
    },
  },
  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const PuritiesList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.purities}
      headerTitle="Purities"
      paginationMeta={{ pageSize: 1 }}
      hideAction={{ delete: true, selection: true, create: true, search: true }}
      onRowClick={false}
    />
  );
};

export default PuritiesList;
