import MainSection from "@ui/MuiComponents/Section/MainSection";
import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core/index";
import { ReactComponent as UploadIcon } from "@ui/assets/commonIcons/upload/uploadIcon2024.svg";
import { ReactComponent as UploadIconOnHover } from "@ui/assets/commonIcons/upload/uploadIconOnHover2024.svg";
import { ReactComponent as DownloadIcon } from "@ui/assets/commonIcons/download/downloadIcon2024.svg";
import { ReactComponent as DownloadIconOnHover } from "@ui/assets/commonIcons/download/downloadIconOnHover2024.svg";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";

import {
  downloadImportTemplate,
  handleDataImport,
} from "@ui/Utils/excelImportHelpers";
import Label from "@ui/components/Label";

const ImportBlueprint = ({
  title,
  model,
  importQueryParams,
  //
  ViewEnumsComponent,
  columns, // = [{ label, name }]
}) => {
  //   const [docsCount, setDocsCount] = useState("0");
  const [files, setFiles] = useState([]);
  const uploadRef = useRef(null);
  const [importResult, setImportResult] = useState({});

  const filteredInvalidRows = Object.keys(
    importResult.invalidRows || {}
  ).filter((col) => importResult.invalidRows[col]?.rows?.length);

  const filteredInvalidExcelData = Object.keys(
    importResult.invalidExcelData || {}
  ).filter((col) => importResult.invalidExcelData[col]?.length);

  const handleFileSelect = (event) => {
    setFiles(event.target.files);
  };

  //   useEffect(() => {
  //     (async () => {
  //       try {
  //         setDocsCount((await countDocuments(model))?.count || "0");
  //       } catch (err) {}
  //     })();
  //   }, []);

  useEffect(() => {
    if (files.length) {
      (async () => {
        await handleImportTemplate();
      })();
    }
  }, [files]);

  const handleDownloadTemplate = async () => {
    try {
      await downloadImportTemplate(title, columns);
    } catch (err) {
      console.log(err);
    }
  };

  const handleImportTemplate = async () => {
    try {
      setImportResult({});
      const res = await handleDataImport(
        model,
        files[0],
        title,
        columns,
        importQueryParams
      );

      setImportResult(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainSection
      title={
        title
        // + ` ( ${docsCount} )` // commenting this to avoid, recalling this API after Data Import is Successfull
      }
      titleStyle={{ fontWeight: "bold", fontSize: "24px", color: "green" }}
      actions={
        ViewEnumsComponent
          ? [{ buttonType: "custom", customIcon: ViewEnumsComponent }]
          : []
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6} container justify="center">
          <MyHoverIcons
            muiIcon={<DownloadIcon />}
            muiIconHover={<DownloadIconOnHover />}
            tooltip={`Download ${title} Template`}
            onClick={handleDownloadTemplate}
          />
        </Grid>

        <Grid item xs={6} container justify="center">
          <MyHoverIcons
            muiIcon={<UploadIcon />}
            muiIconHover={<UploadIconOnHover />}
            tooltip={`Import ${title} Data`}
            onClick={() => uploadRef.current.click()}
          />

          <input
            accept=".xlsx"
            ref={uploadRef}
            style={{ display: "none", cursor: "hand" }}
            onChange={handleFileSelect}
            onClick={(event) => {
              event.target.value = null;
            }}
            type="file"
          />
        </Grid>

        {Object.keys(importResult).length ? (
          <Grid item xs={12} container justify="center">
            {filteredInvalidRows?.length ? (
              <Grid item xs={10}>
                <Label
                  label={"Invalid Rows"}
                  value={
                    <ul>
                      {filteredInvalidRows.map((col, colIndex) => (
                        <li key={colIndex}>
                          <b>{col}: </b>
                          {(importResult.invalidRows[col]?.rows || []).join(
                            ", "
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                />
              </Grid>
            ) : null}

            {filteredInvalidExcelData.length ? (
              <Grid item xs={10}>
                <Label
                  label={"Invalid Excel Data"}
                  value={
                    <ul>
                      {filteredInvalidExcelData.map((col, colIndex) => (
                        <li key={colIndex}>
                          <b>{col}: </b>
                          {(importResult.invalidExcelData[col] || []).join(
                            ", "
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                />
              </Grid>
            ) : null}

            <Grid item xs={10}>
              <Label label={"Created"} value={importResult.created} />
            </Grid>
            <Grid item xs={10}>
              <Label label={"Updated"} value={importResult.updated} />
            </Grid>
            <Grid item xs={10}>
              <Label label={"Total"} value={importResult.total} />
            </Grid>

            <Grid item xs={10}>
              <Label
                label={"Duplicates"}
                value={importResult.duplicates?.join(", ")}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </MainSection>
  );
};

export default ImportBlueprint;
