import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";

const columns = [
  {
    title: "First Name",
    field: "firstName",
    // render: (row) => row?.firstName + " " + row?.lastName,
  },
  {
    title: "Last Name",
    field: "lastName",
    // render: (row) => row?.firstName + " " + row?.lastName,
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "Role",
    field: "role",
  },
  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const EmployeesList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.iam.users}
      headerTitle="Employees"
      searchFields={["firstName", "role"]}
      mainQuery={{
        populate: [{ path: "createdBy", select: "firstName lastName" }],
      }}
      paginationMeta={{ pageSize: 10 }}
      hideAction={{ delete: true, selection: true, create: true }}
      onRowClick={false}
      options={{
        exportButton: {
          csv: true,
          pdf: true,
        },
      }}
    />
  );
};

export default EmployeesList;
