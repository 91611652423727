import React from "react";

// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import ExtensionIcon from "@mui/icons-material/Extension";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";

// Components
import HomePage from "pages/HomePage/HomePage";
import ImportExportIcon from "@mui/icons-material/ImportExport";
// utils
import { isAdmin } from "@ui/Utils/helper";
import enums from "helpers/enums";
import InvoicesList from "views/Invoices/List/List";
import AccountsList from "views/Accounts/List/List";
import EmployeesList from "views/Employees/List/List";
import ProductsList from "views/Products/List/List";
import ExtrasList from "views/Extras/List/List";
import ImportDataList from "views/ImportData/List/List";
import PuritiesList from "views/Purities/List/List";
import PaymentMethodsList from "views/PaymentMethods/List/List";
import GroupsList from "views/Groups/List/List";

export const primaryMenuRoutes = [
  {
    access: true,
    title: "Dashboard",
    path: enums.models.dashboard,
    icon: () => <DashboardIcon />,
    selectedIcon: () => <DashboardIcon />,
    component: () => <HomePage />,
  },
  {
    access: isAdmin,
    title: "Import",
    path: enums.models.import,
    icon: () => <ImportExportIcon />,
    selectedIcon: () => <ImportExportIcon />,
    component: () => <ImportDataList />,
  },
  {
    access: true,
    title: "Invoices",
    path: enums.models.invoices,
    icon: () => <ReceiptLongOutlinedIcon />,
    selectedIcon: () => <ReceiptIcon />,
    component: () => <InvoicesList />,
    hideMenuByDefault: true,
  },
  {
    access: true,
    title: "Products",
    path: enums.models.products,

    icon: () => <CategoryOutlinedIcon />,
    selectedIcon: () => <CategoryIcon />,
    component: () => <ProductsList />,
  },
  {
    access: true,
    title: "Extras",
    path: enums.models.extrasMaster,
    icon: () => <ExtensionOutlinedIcon />,
    selectedIcon: () => <ExtensionIcon />,
    component: () => <ExtrasList />,
  },
  {
    access: true,
    title: "Purity",
    path: enums.models.purities,
    icon: () => <GradeOutlinedIcon />,
    selectedIcon: () => <GradeIcon />,
    component: () => <PuritiesList />,
  },
  {
    access: true,
    title: "Payment",
    path: enums.models.paymentMethods,
    icon: () => <AccountBalanceOutlinedIcon />,
    selectedIcon: () => <AccountBalanceIcon />,
    component: () => <PaymentMethodsList />,
  },
  {
    access: true,
    title: "Groups",
    path: enums.models.groups,
    icon: () => <GroupWorkOutlinedIcon />,
    selectedIcon: () => <GroupWorkIcon />,
    component: () => <GroupsList />,
  },
  {
    access: true,
    title: "Accounts",
    path: enums.models.accounts,
    icon: () => <SupportAgentIcon />,
    // selectedIcon: () => <ImportExportIcon />,
    component: () => <AccountsList />,
    hideMenuByDefault: true,
  },
  {
    access: true,
    title: "Employees",
    path: enums.models.iam.users,
    icon: () => <PersonAddAltIcon />,
    // selectedIcon: () => <ImportExportIcon />,
    component: () => <EmployeesList />,
    hideMenuByDefault: true,
  },
];

export const secondaryMenuRoutes = [];
