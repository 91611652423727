import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const GroupsList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.groups}
      headerTitle="Groups"
      searchFields={["name"]}
      paginationMeta={{ pageSize: 10 }}
      hideAction={{ delete: true, selection: true, create: true }}
      onRowClick={false}
      options={{
        exportButton: {
          csv: true,
          pdf: true,
        },
      }}
    />
  );
};

export default GroupsList;
