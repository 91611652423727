import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApiUrl + `iam/`;
const modelUrl = baseApiUrl + "users";
export const deleteUserRecord = async (phone, client, code) => {
  try {
    const url = commonConfig.baseApiUrl + "c/iam/users/delete";

    const res = await HTTP.post(url, { phone, client, code });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const sendPhoneOtp = async (
  phone,
  { throwIfUserExist, client } //
) => {
  try {
    const url = modelUrl + "/sendPhoneOTP";

    const res = await HTTP.post(url, { phone, throwIfUserExist, client });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyOtp = async (phone, code) => {
  try {
    const url = modelUrl + "/verifyPhoneOTP";

    const res = await HTTP.post(url, { phone, code });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
