import ImportBlueprint from "@ui/Controls/ImportBlueprint/ImportBlueprint";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  Tooltip,
  Typography,
} from "@material-ui/core/index";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import { isDivisionControlled, selectedDivisionName } from "@ui/Utils/helper";
import {
  getUniversalAccessToken,
  getCountries,
  getStates,
  getCities,
} from "@ui/ComponentUtils/thirdPartyAPIs";
import {
  MyAutocomplete,
  MyDialogContent,
  MyDialogTitle,
} from "@ui/MuiComponents/index";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import MainSection from "@ui/MuiComponents/Section/MainSection";

const ImportDataList = () => {
  const [availableGroups, setAvailableGroups] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [universalTutorialToken, setUniversalTutorialToken] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res =
          (await search(enums.models.groups, { filterColumns: { name: 1 } })) ||
          [];

        setAvailableGroups(res.map((group) => group.name));
      } catch (err) {}
    })();

    (async () => {
      try {
        const token = await getUniversalAccessToken();

        const countries = await getCountries(token);

        setUniversalTutorialToken(token);

        setAvailableCountries(
          countries.map((country) => country?.country_name)
          // .filter(
          //   (country) => country === "India" || country === "United States"
          // )
        );
      } catch (err) {}
    })();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container justify="center">
        <h1>Import</h1>
        <ImportExportIcon
          style={{
            fontSize: "48px",
            marginLeft: "8px",
            marginTop: "24px",
            color: "green",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {availableGroups?.length && availableCountries?.length ? (
          <ImportBlueprint
            ViewEnumsComponent={
              <AccountsEnumsComponent
                availableCountries={availableCountries}
                token={universalTutorialToken}
                availableGroups={availableGroups}
              />
            }
            title={"Accounts"}
            model={enums.models.accounts}
            importQueryParams={`hook=true`}
            columns={[
              {
                key: "companyName",
                header: "Company Name",
                required: true,
              },
              {
                key: "contactName",
                header: "Contact Name",
                required: true,
              },

              {
                key: "phone",
                header: "Phone",
                required: true,
                unique: true,
                dataType: enums.dataTypes.number,
              },
              {
                key: "gstNumber",
                header: "GST Number",
                maxLength: 15,
              },
              {
                key: "notes",
                header: "Notes",
              },
              {
                key: "group.name",
                header: "Group",
                required: true,
                enums: availableGroups,
              },
              {
                key: `initialBalanceAmount`,
                header: isDivisionControlled
                  ? `Initial Balance Amount in ${selectedDivisionName}`
                  : `Initial Balance Amount`,
                width: 50,
                dataType: enums.dataTypes.number,
              },
              {
                key: `initialBalanceFineGold`,
                header: isDivisionControlled
                  ? `Initial Balance Gold in ${selectedDivisionName}`
                  : "Initial Balance Gold",
                width: 50,
                dataType: enums.dataTypes.number,
              },
              {
                key: "address.country",
                header: "Country",
                enums: availableCountries,
                required: true,
              },
              {
                key: "address.state",
                header: "State",
                required: true,
              },
              {
                key: "address.city",
                header: "City",
              },

              {
                key: "address.street",
                header: "Street",
              },

              {
                key: "address.pincode",
                header: "Pincode",
                required: true,
              },
            ]}
          />
        ) : (
          <MainSection title={"Accounts"}>
            <Grid container spacing={3}>
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ color: "red" }}
              >
                {availableGroups.length
                  ? null
                  : "Add Groups Master Data to Continue"}
              </Grid>
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ color: "red" }}
              >
                {availableCountries.length
                  ? null
                  : "Countries Not Available, Refresh to try again"}
              </Grid>
            </Grid>
          </MainSection>
        )}
      </Grid>
      <Grid item xs={12}>
        <ImportBlueprint
          title={"Products"}
          model={enums.models.products}
          columns={[
            {
              key: "name",
              header: "Name",
              unique: true,
              required: true,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

const AccountsEnumsComponent = ({
  availableCountries,
  token,
  availableGroups,
}) => {
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  //   const [city, setCity] = useState("");
  const [availableStates, setAvailableStates] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [openEnumsPopup, setOpenEnumsPopup] = useState(false);

  const handleStates = async (country) => {
    try {
      setAvailableStates([]);
      const res = await getStates(token, country);
      setAvailableStates(res.map((data) => data.state_name));
    } catch (err) {
      console.log(err);
    }
  };

  const handleCities = async (state) => {
    try {
      setAvailableCities([]);
      const res = await getCities(token, state);
      setAvailableCities(res.map((data) => data.city_name));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid>
      <MyHoverIcons
        muiIcon={<EventOutlinedIcon />}
        muiIconHover={<EventNoteIcon />}
        onClick={() => setOpenEnumsPopup(true)}
        tooltip={"Valid Excel Constants"}
      />

      <Dialog
        open={openEnumsPopup}
        onClose={() => setOpenEnumsPopup(false)}
        fullWidth
      >
        <MyDialogTitle
          onClose={() => setOpenEnumsPopup(false)}
          style={{
            backgroundColor: "black",
            color: "white",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a cool box shadow
          }}
        >
          <b
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "monospace",
            }}
          >
            Valid Excel Constants
          </b>
        </MyDialogTitle>

        <MyDialogContent
          style={{ margin: "48px", paddingTop: "-44px", marginTop: 0 }}
        >
          <Grid container spacing={3} style={{ marginTop: "2px" }}>
            <Grid item xs={12}>
              <Typography color="secondary">Country, State, City</Typography>
            </Grid>
            <Grid item xs={4}>
              <MyAutocomplete
                label="Country"
                options={availableCountries}
                value={country}
                onChange={(_, val) => {
                  handleStates(val);
                  setCountry(val);
                  setState("");
                }}
              />
            </Grid>
            {country && availableStates?.length ? (
              <Grid item xs={4}>
                <MyAutocomplete
                  label="State"
                  options={availableStates}
                  value={state}
                  onChange={(_, val) => {
                    handleCities(val);
                    setState(val);
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={4}>
              {state && availableCities.length ? (
                <MyAutocomplete
                  label="City"
                  options={availableCities}
                  onChange={(_, val) => {
                    //   setState(val);
                  }}
                />
              ) : null}
            </Grid>

            {/* 
            // As Country flag is auto filled in backend with selected country
            <Grid item xs={12}>
              <Typography color="secondary">Country Flag</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">{["IN", "US"].join(", ")}</Typography>
            </Grid> */}

            <Grid item xs={12}>
              <Typography color="secondary">Group</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                {availableGroups.join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </MyDialogContent>
      </Dialog>
    </Grid>
  );
};

export default ImportDataList;
