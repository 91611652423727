import React, { useState } from "react";
import { deleteUserRecord, sendPhoneOtp, verifyOtp } from "./api";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

const DeleteUserAccount = () => {
  const [disableOTP, setDisableOTP] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState({});

  const sendOtp = async () => {
    try {
      if (document.getElementsByName("phone")[0].value?.toString().trim()) {
        setDisableOTP(true);
        setTimeout(() => setDisableOTP(false), 10000);

        const res = await sendPhoneOtp(
          document.getElementsByName("phone")[0].value,
          { client: { $exists: true } }
        );

        if (res.data?.data?.user?._id) {
          setUser(res.data?.data?.user);

          CustomEventEmitter.emit("alert_success", "OTP SENT");
        } else {
          setError("User not found");
        }
      } else {
        alert("Invalid Phone Number");
      }
    } catch (err) {
      CustomEventEmitter.emit("alert_error", "Something went wrong");
      console.log(err);
    }
  };

  const verifyPhoneOTP = async () => {
    try {
      const res = await verifyOtp(
        document.getElementsByName("phone")[0].value,
        document.getElementsByName("otp")[0].value
      );
      if (res?.data?.success) {
        CustomEventEmitter.emit("alert_success", "OTP Verified Successfully");
        await deleteUserRecord(user.phone, user.client?._id, user.code);
        CustomEventEmitter.emit(
          "alert_success",
          "Deleted User Account Successfully"
        );
      }
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit("alert_error", "Something went wrong");
    }
  };

  return (
    <div>
      <center>
        Delete Account
        <br />
        <br />
        <br />
        <h3>Phone: </h3>
        <input type="number" name="phone" />
        <button onClick={() => sendOtp()} disabled={disableOTP}>
          {`Send OTP ${disableOTP ? "( 30 seconds ) " : ""}`}
        </button>
        {user?._id ? (
          <>
            <ul>
              <li>Name: {user?.firstName + " " + user?.lastName}</li>
              <li>Client: {user?.client?.name}</li>
              <li>Role: {user?.role}</li>
            </ul>
            <h3>OTP: </h3>
            <input type="number" name="otp" />
            <button onClick={() => verifyPhoneOTP()}>
              Verify OTP and Delete Account
            </button>
          </>
        ) : (
          <div>{error}</div>
        )}
      </center>
    </div>
  );
};

export default DeleteUserAccount;
