const enums = {
  stockStatus: {
    staged: "Staged",
    available: "Available",
    approval: "Approval",
    issued: "Issued",
    unAvailable: "Unavailable",
  },

  orderStatus: {
    inReview: "In review",
    accepted: "Accepted",
    making: "Making",
    dispatched: "Dispatched",
    delivered: "Delivered",
    canceled: "Canceled",
  },

  keywords: {
    selectAll: "Select All",
    includedUsers: {
      public: "All Unregistered Users",
      private: "All Registered Users",
    },
  },

  workflow: {
    wholesale: "Wholesale",
    productWise: "Product Wise",
  },

  roles: {
    superAdmin: "Super Admin",
    admin: "Admin",
    customer: "Customer",
    manager: "Manager",
    salesPerson: "Sales Person",
  },

  fieldTypes: {
    textField: "textField",
    dropdown: "dropdown",
    upload: "upload",
  },
  models: {
    import: "chitthi/import",
    dashboard: "chitthi/dashboard",
    invoices: "chitthi/invoices",
    accounts: "chitthi/accounts",
    products: "chitthi/products",
    extrasMaster: "chitthi/extrasMaster",
    groups: "chitthi/groups",
    purities: "chitthi/purities",
    paymentMethods: "chitthi/paymentMethods",

    iam: {
      clients: "iam/clients",
      users: "iam/users",
    },
  },

  mobileAppScreenNames: {
    home: "Home",
    productsList: "Products List",
    productDetail: "Product Detail",
    wishList: "Wish List",
    cart: "Cart",
    orders: "Orders Group",

    signin: "Signin",
    signup: "Signup",
  },

  designPatterns: ["Default", "Greenish"],

  dataTypes: {
    string: "string",
    number: "number",
    object: "object",
    array: "array",
  },
};

export default enums;
