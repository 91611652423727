import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { bulkImportData } from "@ui/ComponentUtils/blueprintAPIs";
import { findDuplicates } from "./helper";
import enums from "helpers/enums";

const chunkSize = 5000;

const defaultCellWidth = 20;

const defaultRowHeight = {
  header: 25,
  productHeader: 35,
};

export const sheetNames = {
  info: "Information",
  products: "Stocks",
  diamondDetails: "Diamonds",
  missingImages: "Missing Imagesf",
};

export const alphabets = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AH",
  "AI",
  "AJ",
  "AK",
  "AL",
  "AM",
  "AN",
  "AO",
  "AP",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AV",
  "AW",
  "AX",
  "AY",
  "AZ",
  "BA",
  "BB",
  "BC",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BK",
  "BL",
  "BM",
  "BN",
  "BO",
  "BP",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BU",
  "BV",
  "BW",
  "BX",
  "BY",
  "BZ",
  "CA",
  "CB",
  "CC",
  "CD",
  "CE",
  "CF",
  "CG",
  "CH",
  "CI",
  "CJ",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CP",
  "CQ",
  "CR",
  "CS",
  "CT",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DA",
  "DB",
  "DC",
  "DD",
  "DE",
  "DF",
  "DG",
  "DH",
  "DI",
  "DJ",
  "DK",
  "DL",
  "DM",
  "DN",
  "DO",
  "DP",
  "DQ",
  "DR",
  "DS",
  "DT",
  "DU",
  "DV",
  "DW",
  "DX",
  "DY",
  "DZ",
  "EA",
  "EB",
  "EC",
  "ED",
  "EE",
  "EF",
  "EG",
  "EH",
  "EI",
  "EJ",
  "EK",
  "EL",
  "EM",
  "EN",
  "EO",
  "EP",
  "EQ",
  "ER",
  "ES",
  "ET",
  "EU",
  "EV",
  "EW",
  "EX",
  "EY",
  "EZ",
  "FA",
  "FB",
  "FC",
  "FD",
  "FE",
  "FF",
  "FG",
  "FH",
  "FI",
  "FJ",
  "FK",
  "FL",
  "FM",
  "FN",
  "FO",
  "FP",
  "FQ",
  "FR",
  "FS",
  "FT",
  "FU",
  "FV",
  "FW",
  "FX",
  "FY",
  "FZ",
  "GA",
  "GB",
  "GC",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GJ",
  "GK",
  "GL",
  "GM",
  "GN",
  "GO",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GV",
  "GW",
  "GX",
  "GY",
  "GZ",
  "HA",
  "HB",
  "HC",
  "HD",
  "HE",
  "HF",
  "HG",
  "HH",
  "HI",
  "HJ",
  "HK",
  "HL",
  "HM",
  "HN",
  "HO",
  "HP",
  "HQ",
  "HR",
  "HS",
  "HT",
  "HU",
  "HV",
  "HW",
  "HX",
  "HY",
  "HZ",
  "IA",
  "IB",
  "IC",
  "ID",
  "IE",
  "IF",
  "IG",
  "IH",
  "II",
  "IJ",
  "IK",
  "IL",
  "IM",
  "IN",
  "IO",
  "IP",
  "IQ",
  "IR",
  "IS",
  "IT",
  "IU",
  "IV",
  "IW",
  "IX",
  "IY",
  "IZ",
  "JA",
  "JB",
  "JC",
  "JD",
  "JE",
  "JF",
  "JG",
  "JH",
  "JI",
  "JJ",
  "JK",
  "JL",
  "JM",
  "JN",
  "JO",
  "JP",
  "JQ",
  "JR",
  "JS",
  "JT",
  "JU",
  "JV",
  "JW",
  "JX",
  "JY",
  "JZ",
  "KA",
  "KB",
  "KC",
  "KD",
  "KE",
  "KF",
  "KG",
  "KH",
  "KI",
  "KJ",
  "KK",
  "KL",
  "KM",
  "KN",
  "KO",
  "KP",
  "KQ",
  "KR",
  "KS",
  "KT",
  "KU",
  "KV",
  "KW",
  "KX",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LD",
  "LE",
  "LF",
  "LG",
  "LH",
  "LI",
  "LJ",
  "LK",
  "LL",
  "LM",
  "LN",
  "LO",
  "LP",
  "LQ",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LW",
  "LX",
  "LY",
  "LZ",
  "MA",
  "MB",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MI",
  "MJ",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NB",
  "NC",
  "ND",
  "NE",
  "NF",
  "NG",
  "NH",
  "NI",
  "NJ",
  "NK",
  "NL",
  "NM",
  "NN",
  "NO",
  "NP",
  "NQ",
  "NR",
  "NS",
  "NT",
  "NU",
  "NV",
  "NW",
  "NX",
  "NY",
  "NZ",
  "OA",
  "OB",
  "OC",
  "OD",
  "OE",
  "OF",
  "OG",
  "OH",
  "OI",
  "OJ",
  "OK",
  "OL",
  "OM",
  "ON",
  "OO",
  "OP",
  "OQ",
  "OR",
  "OS",
  "OT",
  "OU",
  "OV",
  "OW",
  "OX",
  "OY",
  "OZ",
  "PA",
  "PB",
  "PC",
  "PD",
  "PE",
  "PF",
  "PG",
  "PH",
  "PI",
  "PJ",
  "PK",
  "PL",
  "PM",
  "PN",
  "PO",
  "PP",
  "PQ",
  "PR",
  "PS",
  "PT",
  "PU",
  "PV",
  "PW",
  "PX",
  "PY",
  "PZ",
  "QA",
  "QB",
  "QC",
  "QD",
  "QE",
  "QF",
  "QG",
  "QH",
  "QI",
  "QJ",
  "QK",
  "QL",
  "QM",
  "QN",
  "QO",
  "QP",
  "QQ",
  "QR",
  "QS",
  "QT",
  "QU",
  "QV",
  "QW",
  "QX",
  "QY",
  "QZ",
  "RA",
  "RB",
  "RC",
  "RD",
  "RE",
  "RF",
  "RG",
  "RH",
  "RI",
  "RJ",
  "RK",
  "RL",
  "RM",
  "RN",
  "RO",
  "RP",
  "RQ",
  "RR",
  "RS",
  "RT",
  "RU",
  "RV",
  "RW",
  "RX",
  "RY",
  "RZ",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SF",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SP",
  "SQ",
  "SR",
  "SS",
  "ST",
  "SU",
  "SV",
  "SW",
  "SX",
  "SY",
  "SZ",
  "TA",
  "TB",
  "TC",
  "TD",
  "TE",
  "TF",
  "TG",
  "TH",
  "TI",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TP",
  "TQ",
  "TR",
  "TS",
  "TT",
  "TU",
  "TV",
  "TW",
  "TX",
  "TY",
  "TZ",
  "UA",
  "UB",
  "UC",
  "UD",
  "UE",
  "UF",
  "UG",
  "UH",
  "UI",
  "UJ",
  "UK",
  "UL",
  "UM",
  "UN",
  "UO",
  "UP",
  "UQ",
  "UR",
  "US",
  "UT",
  "UU",
  "UV",
  "UW",
  "UX",
  "UY",
  "UZ",
  "VA",
  "VB",
  "VC",
  "VD",
  "VE",
  "VF",
  "VG",
  "VH",
  "VI",
  "VJ",
  "VK",
  "VL",
  "VM",
  "VN",
  "VO",
  "VP",
  "VQ",
  "VR",
  "VS",
  "VT",
  "VU",
  "VV",
  "VW",
  "VX",
  "VY",
  "VZ",
  "WA",
  "WB",
  "WC",
  "WD",
  "WE",
  "WF",
  "WG",
  "WH",
  "WI",
  "WJ",
  "WK",
  "WL",
  "WM",
  "WN",
  "WO",
  "WP",
  "WQ",
  "WR",
  "WS",
  "WT",
  "WU",
  "WV",
  "WW",
  "WX",
  "WY",
  "WZ",
  "XA",
  "XB",
  "XC",
  "XD",
  "XE",
  "XF",
  "XG",
  "XH",
  "XI",
  "XJ",
  "XK",
  "XL",
  "XM",
  "XN",
  "XO",
  "XP",
  "XQ",
  "XR",
  "XS",
  "XT",
  "XU",
  "XV",
  "XW",
  "XX",
  "XY",
  "XZ",
  "YA",
  "YB",
  "YC",
  "YD",
  "YE",
  "YF",
  "YG",
  "YH",
  "YI",
  "YJ",
  "YK",
  "YL",
  "YM",
  "YN",
  "YO",
  "YP",
  "YQ",
  "YR",
  "YS",
  "YT",
  "YU",
  "YV",
  "YW",
  "YX",
  "YY",
  "YZ",
  "ZA",
  "ZB",
  "ZC",
  "ZD",
  "ZE",
  "ZF",
  "ZG",
  "ZH",
  "ZI",
  "ZJ",
  "ZK",
  "ZL",
  "ZM",
  "ZN",
  "ZO",
  "ZP",
  "ZQ",
  "ZR",
  "ZS",
  "ZT",
  "ZU",
  "ZV",
  "ZW",
  "ZX",
  "ZY",
  "ZZ",
  "AAA",
  "AAB",
  "AAC",
  "AAD",
  "AAE",
  "AAF",
  "AAG",
  "AAH",
  "AAI",
  "AAJ",
  "AAK",
  "AAL",
  "AAM",
  "AAN",
  "AAO",
  "AAP",
  "AAQ",
  "AAR",
  "AAS",
  "AAT",
  "AAU",
]; // For Excel Cell Names

export const excelDateFormat = "DD/MM/YYYY";

export const excelFont = {
  size: {
    headers: 12,
    infoLabel: 12,
    infoValue: 14,
  },
  bold: {
    headers: true,
    infoLabel: false,
    infoValue: true,
  },
  color: {
    headers: "FFE08C",
    sectionHeading: "9cffd5",
    infoLabel: "F7F7F7",
    infoValue: "FFE08C",
    listHeaders: "7e9cd9",
  },
};

export const excelAlignment = {
  headers: {
    vertical: "middle",
    horizontal: "center",
  },
  body: {
    wrap: true,
  },
  colWidth: {
    infoLabel: 20,
    infoValue: 60,
  },
};

export const getCellStyle = (fontSize, color, bold) => {
  // //name: 'Comic Sans MS'
  return {
    font: { size: fontSize, bold: bold },
    fill: {
      type: "gradient",
      gradient: "angle",
      degree: 0,
      stops: [
        { position: 0, color: { argb: color || "FFFFFF" } },
        { position: 0.5, color: { argb: color || "FFFFFF" } },
        { position: 1, color: { argb: color || "FFFFFF" } },
      ],
    },
  };
};

export const getExcelDropdown = (options = []) => {
  return {
    type: "list",
    allowBlank: false,
    formulae: [`"${options ? options.join(",") : ""}"`],
    showErrorMessage: true,
    errorStyle: "error",
    error: "Invalid Option",
  };
};

export const stringToDate = (_date, _format, _delimiter) => {
  let formatLowerCase = _format.toLowerCase();
  let formatItems = formatLowerCase.split(_delimiter);
  let dateItems = _date.split(_delimiter);
  let monthIndex = formatItems.indexOf("mm");
  let dayIndex = formatItems.indexOf("dd");
  let yearIndex = formatItems.indexOf("yyyy");
  let month = parseInt(dateItems[monthIndex]);
  month -= 1;
  let formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  return formatedDate;
};

export const getExcelDateToJSDate = (date) => {
  if (date?.toString().includes("/")) {
    date = date.toString();
    date = stringToDate(date, "dd/MM/yyyy", "/");

    return date;
  } else {
    return new Date(Math.round((date - 25569) * 86400 * 1000));
  }
};

export const downloadExcelFile = (workbook, excelPageTitle) => {
  try {
    workbook.xlsx
      .writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        var link = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = link;
        a.download = `${excelPageTitle}.xlsx`;
        a.click();

        CustomEventEmitter.emit("alert_success", "Downloaded Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    console.log(err);
  }
};

const addMainColumns = (sheet, columns) => {
  try {
    sheet.getRow("1").height = defaultRowHeight.header;
    sheet.columns = columns.map((col) => ({
      header: col.header,
      key: col.key,
      width: col.width || defaultCellWidth,
    }));

    for (let colIndex = 0; colIndex < columns.length; colIndex++) {
      // const columnHeader = columns[colIndex].header;

      // Styling only HEADERS
      sheet.getCell(`${alphabets[colIndex] + 1}`).style = getCellStyle(
        excelFont.size.headers,
        excelFont.color.headers,
        excelFont.bold.headers
      );

      sheet.getColumn(`${alphabets[colIndex]}`).alignment = {
        wrapText: excelAlignment.body.wrap,
      };

      sheet.getCell(`'${alphabets[colIndex] + 1}'`).alignment = {
        vertical: excelAlignment.headers.vertical,
        horizontal: excelAlignment.headers.horizontal,
        wrapText: true,
      };

      if (columns[colIndex].enums?.length) {
        sheet.dataValidations.add(
          `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
          getExcelDropdown(columns[colIndex].enums)
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const downloadImportTemplate = async (title, columns) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const mainSheet = workbook.addWorksheet(title);

    addMainColumns(mainSheet, columns);

    downloadExcelFile(workbook, title);
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Something went wrong!");
  }
};

export const handleDataImport = async (
  model,
  importedFile,
  title,
  columns,
  importQueryParams
  // setReadingFiles = () => {},
  // setReadingRows = () => {}
) => {
  const invalidExcelData = []; // from backend validation
  // responseArray = [];
  let total = 0,
    created = 0,
    updated = 0,
    failed = [],
    invalidRows = {},
    duplicates = [];

  try {
    async function processImportedFile(importedFile) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(importedFile);
        reader.onloadend = async function (evt) {
          if (evt.target.readyState == FileReader.DONE) {
            const arrayBuffer = evt.target.result,
              array = new Uint8Array(arrayBuffer);

            const workbook = XLSX.read(array, { type: "array" });
            let columnsArray;
            const data = {};
            workbook.SheetNames.forEach((sheetName) => {
              columnsArray = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheetName],
                {
                  header: 1,
                }
              )[0];
              var rowObj = XLSX.utils.sheet_to_row_object_array(
                workbook.Sheets[sheetName]
              );
              var rowString = rowObj;
              data[sheetName] = rowString;
            });

            let excelData = data[title] || [];
            let invalidFlag = false;

            // Validation Starts
            const uniqueColumn = columns.find((col) => col.unique);
            const uniqueHeader = uniqueColumn?.header;

            if (!uniqueHeader) {
              // from Code there should be at least one unique column for each collection
              invalidFlag = true;
            } else {
              duplicates = findDuplicates(excelData, uniqueHeader);
              if (duplicates.length) invalidFlag = true;

              excelData = excelData.filter((data, rowIndex) => {
                columns.map((col) => {
                  invalidRows[col.header] = invalidRows[col.header] || {
                    rows: [],
                  };

                  if (col.required && !data[col.header]?.toString().trim()) {
                    invalidFlag = true;
                    invalidRows[col.header].rows.push(rowIndex + 2);
                  }

                  if (data[col.header]?.toString().trim()) {
                    if (
                      col.dataType === enums.dataTypes.number &&
                      isNaN(data[col.header])
                    ) {
                      invalidFlag = true;
                      invalidRows[col.header].rows.push(rowIndex + 2);
                    }

                    if (col.maxLength) {
                      if (
                        data[col.header]?.toString().length !== col.maxLength
                      ) {
                        invalidFlag = true;
                        invalidRows[col.header].rows.push(rowIndex + 2);
                      }
                    }
                  }
                });

                return !invalidFlag;
              });
              // Validation Ends

              if (invalidFlag) {
                CustomEventEmitter.emit("alert_error", "Invalid Data Found");
                return reject("Invalid Data Found");
              } else if (excelData?.length > 0) {
                const chunks = Math.ceil(excelData.length / chunkSize);
                let min = 0,
                  max = chunkSize;

                for (let i = 0; i < chunks; i++) {
                  const paginated = excelData.slice(min, max);

                  try {
                    const res = await bulkImportData(
                      model,
                      {
                        excelData: paginated,
                        columns: columns,
                      },
                      { params: importQueryParams }
                    );

                    // responseArray.push(res);

                    created += Number(res.created);
                    updated += Number(res.updated);

                    Object.keys(res.invalidExcelData || {}).map((resKey) => {
                      invalidExcelData[resKey] = [
                        ...(invalidExcelData[resKey] || []),
                        ...(res.invalidExcelData[resKey] || []),
                      ];
                    });

                    // setReadingRows(
                    //   `${min + paginated.length} / ${excelData.length}`
                    // );

                    CustomEventEmitter.emit(
                      "alert_info",
                      `Imported: ${created + updated} / ${excelData.length}`
                    );
                    min += chunkSize;
                    max += chunkSize;
                  } catch (err) {
                    console.log(err);
                    CustomEventEmitter.emit(
                      "alert_error",
                      "Some rows failed in the middle..."
                    );
                  }
                }

                CustomEventEmitter.emit("alert_success", `Import Completed`);

                total = excelData.length;

                resolve(); // Resolve the promise when processing is complete
              } else {
                CustomEventEmitter.emit("alert_error", "No Data to Import");
                reject("No Data to Import");
              }
            }
          }
        };
      });
    }

    // Example of using await:

    await processImportedFile(importedFile); // replace 'yourImportedFile' with the actual file input
    // Call the exampleUsage function whenever you want to process the file
  } catch (err) {
    console.log(err);
    // CustomEventEmitter.emit("alert_error", "Import Failure");
  }
  // setReadingFiles(false);
  return {
    total,
    created,
    updated,
    // failed,
    invalidRows,
    duplicates,

    invalidExcelData,
    // responseArray,
  };
};
