import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, joinWithSeparator } from "@ui/Utils/helper";

const columns = [
  {
    title: "Contact Name",
    field: "contactName",
  },
  {
    title: "Company Name",
    field: "companyName",
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "GST",
    field: "gstNumber",
  },
  {
    title: "Group",
    field: "group.name",
  },
  {
    title: "Initial Amount Balance",
    // field: "divisionBalance.initialBalanceAmount",
    sorting: false,
    render: (row) =>
      Object.keys(row.divisionBalance || {})
        .map((divisionId) => row.divisionBalance[divisionId])
        .reduce(
          (a, b) =>
            (a?.initialBalanceAmount || 0) + (b?.initialBalanceAmount || 0),
          0
        ),
  },
  {
    title: "Initial Gold Balance",
    sorting: false,
    render: (row) =>
      Object.keys(row.divisionBalance || {})
        .map((divisionId) => row.divisionBalance[divisionId])
        .reduce(
          (a, b) =>
            (a?.initialBalanceFineGold || 0) + (b?.initialBalanceFineGold || 0),
          0
        ),
  },

  {
    title: "Address",
    render: (row) =>
      joinWithSeparator([
        row.address?.country,
        row?.address.state,
        row?.address.city,
      ]),
  },
  {
    title: "Notes",
    field: "notes",
  },
];

const AccountsList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.accounts}
      headerTitle="Accounts"
      searchFields={[
        "companyName",
        "contactName",
        "notes",
        "group.name",
        "gstNumber",
        "address.state",
        "address.country",
        "address.city",
        "address.pincode",
      ]}
      numberFields={"phone"}
      paginationMeta={{ pageSize: 100 }}
      hideAction={{ delete: true, selection: true, create: true }}
      onRowClick={false}
      options={{
        exportButton: {
          csv: true,
          pdf: true,
        },
      }}
    />
  );
};

export default AccountsList;
