import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Typography, Paper, Grid, Tooltip, Icon } from "@mui/material";
import { promptPassword } from "./promptPassword";
import { Document, Page, pdfjs } from "react-pdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopIcon from "@mui/icons-material/Stop";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import DeleteIcon from "@mui/icons-material/Delete";
import ReactDocViewer from "./ReactDocViewer";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { oopsNotFound } from "@ui/Utils/helper";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const styles = {
  root: {
    margin: 0,
    padding: "16px",
    backgroundColor: "#ecffff",
    minHeight: "50px",
  },
  closeButton: {
    display: "flex",
    flexDirection: "row",
    right: "8px",
    top: "8px",
    padding: "10px",
  },

  button: {
    cursor: "pointer",
    color: "grey",
    marginRight: "5px",
    marginLeft: "10px",
  },
};

const MyDialogTitle = (props) => {
  const {
    children,
    onClose,
    setPdfDialog,
    currentFile,
    autoPlay,
    setAutoPlay,
    fullScreen,
    setFullScreen,
    items,
    addMore, // to add more files to existing and will be handled by the calling component
    ...other
  } = props;

  const [cancelHover, setCancelHover] = useState(false);

  const urlHref = currentFile?.file?.urlHref;

  return (
    <DialogTitle disableTypography style={styles.root} {...other}>
      <Grid container direction="row">
        <Grid item xs={12} sm={urlHref ? 4 : 7}></Grid>
        <Grid
          container
          xs={12}
          sm={urlHref ? 8 : 5}
          item
          justifyContent="flex-end"
          // style={styles.closeButton}
        >
          {urlHref && (
            <Grid item style={styles.button}>
              <Tooltip title={urlHref.tooltip || "Click here"}>
                <a
                  href={urlHref.externalLink}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  {urlHref.datesData?.name}
                </a>
              </Tooltip>
            </Grid>
          )}

          {items?.length > 1 && (
            <Grid
              item
              style={styles.button}
              onClick={() => setAutoPlay(!autoPlay)}
            >
              <Tooltip
                title={autoPlay ? "Stop Autoplay" : "Autoplay SlideShow"}
              >
                {autoPlay ? <StopIcon /> : <PlayCircleOutlineIcon />}
              </Tooltip>
            </Grid>
          )}

          <Grid
            item
            style={styles.button}
            onClick={() => setFullScreen(!fullScreen)}
          >
            <Tooltip title={fullScreen ? "Exit" : "Fullscreen"}>
              {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </Tooltip>
          </Grid>

          {onClose && (
            <Grid
              item
              style={styles.button}
              id="close"
              onMouseEnter={() => setCancelHover(true)}
              onMouseLeave={() => setCancelHover(false)}
              onClick={setPdfDialog}
            >
              <Tooltip title={"Close"}>
                {cancelHover ? <CancelIcon /> : <CancelOutlinedIcon />}
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

const MyCarousel = ({
  items,
  openPreview,
  setOpenPreview,
  removeData,
  addMore,
  signed = true,
  hideAction = { download: false },
}) => {
  const [currentFile, setCurrentFile] = useState({});
  const [autoPlay, setAutoPlay] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [stateItems, setStateItems] = useState([]);

  useEffect(() => {
    setStateItems(items || []);
  }, [items]);

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth={"md"}
      aria-labelledby="customized-dialog-title"
      open={openPreview}
      onClose={() => setOpenPreview(false)}
    >
      <MyDialogTitle
        id="customized-dialog-title"
        setPdfDialog={() => setOpenPreview(false)}
        onClose={() => setOpenPreview(false)}
        currentFile={currentFile}
        autoPlay={autoPlay}
        setAutoPlay={setAutoPlay}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        items={stateItems}
        addMore={addMore}
      >
        {/* {(currentFile?.file?.fileType?.split("/")[0] === "image" ||
          allowedReactDocViewerTypes.includes(currentFile?.file?.fileType)) &&
          currentFile?.file.fileName}
        */}
      </MyDialogTitle>
      <DialogContent>
        <Carousel
          autoPlay={autoPlay}
          navButtonsAlwaysVisible={!(autoPlay || stateItems?.length === 1)}
          stopAutoPlayOnHover={false}
          navButtonsAlwaysInvisible={stateItems?.length === 1}
          animation="slide"
        >
          {[...stateItems].map((item, i) => (
            <div key={i}>
              <Item
                index={i}
                item={item}
                setCurrentFile={setCurrentFile}
                fullScreen={fullScreen}
                removeData={removeData}
                signed={signed}
                hideAction={hideAction}
              />
            </div>
          ))}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};

const Item = ({
  item,
  setCurrentFile,
  index,
  fullScreen,
  removeData,
  signed,
  hideAction = {},
}) => {
  useEffect(() => {
    setCurrentFile(_.cloneDeep({ file: item, index: index }));
  }, [item, index]);

  const downloadFile = () => {
    try {
      const a = document.createElement("a");
      a.href = signed ? item?.signedUrl : item?.location;
      a.download = `${item?.fileName}.${item?.fileType?.split("/")[1]}`;
      a.target = "hiddenIframe";
      a.click();

      const alertSession = "alertRetryDownload";
      const isAlert = window.sessionStorage.getItem(alertSession);

      if (!isAlert) {
        window.alert(
          "Reload and retry to download if the document is not available to download!"
        );

        window.sessionStorage.setItem(alertSession, true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper style={{ textAlign: "center", overflow: "hidden" }}>
      <Typography variant="h5" style={{ fontWeight: 600 }}>
        {item.fileName}
      </Typography>
      {item.fileType?.split("/")[0] === "image" ? (
        <div>
          <img
            src={signed ? item.signedUrl : item.location}
            style={{
              height: fullScreen ? "80vh" : "65vh",
              maxWidth: "100%",
              objectFit: "contain",
            }}
            alt={item.fileName}
            onError={(event) => {
              event.target.src = oopsNotFound;
            }}
          />
        </div>
      ) : item.fileType === "application/pdf" ? (
        <div
          style={{
            height: fullScreen ? "100%" : "520px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "8px",
            paddingRight: "8px",
            overflow: fullScreen ? "hidden" : "auto",
          }}
        >
          <div style={{ margin: "auto" }}>
            <PDFViewer pdfUrl={signed ? item.signedUrl : item.location} />
          </div>
          {/* firstPagePreview={!!item?.urlHref?.externalLink?.toString().trim()}	 */}
        </div>
      ) : (
        <div
          style={{
            height: fullScreen ? "80vh" : "65vh",
            paddingLeft: 60,
            paddingRight: 60,
            paddingBottom: 50,
          }}
        >
          {item?.fileType &&
          allowedReactDocViewerTypes.includes(item?.fileType) ? (
            <ReactDocViewer
              style={{ height: fullScreen ? "80vh" : "65vh" }}
              file={item.signedUrl || item.location} // for diva screen in Data-Engine, we have images from our bucket which is signed and Diva images which are public
            />
          ) : (
            <div style={{ paddingTop: 260 }}>
              <div>
                <Tooltip title="Download File">
                  <InsertDriveFileIcon
                    onClick={downloadFile}
                    style={{ cursor: "pointer", fontSize: 60 }}
                  />
                </Tooltip>
              </div>
              <div>
                <Typography variant="h5">{item.fileName}</Typography>
              </div>
            </div>
          )}
        </div>
      )}

      {removeData && (
        <Grid
          item
          // container
          style={{ ...styles.button, color: "red" }}
          // justify="flex-end"
        >
          <Tooltip title={"Delete"}>
            <DeleteIcon onClick={() => removeData(index)} />
          </Tooltip>
        </Grid>
      )}
      {hideAction.download ? null : (
        <Grid item style={{ ...styles.button, color: "green" }}>
          <Tooltip title={"Download"}>
            <CloudDownloadIcon onClick={downloadFile} />
          </Tooltip>
        </Grid>
      )}
    </Paper>
  );
};

const allowedReactDocViewerTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

  // 'text/htm',
  // 'text/html',
  // 'application/vnd.ms-powerpoint',
  // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // 'text/plain',
];

export const PDFViewer = (props) => {
  const { pdfUrl, height, width, firstPagePreview } = props;
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Document
      error={isPasswordProtected ? "Failed to open" : "Loading.."}
      file={pdfUrl}
      // loading={props.loader}
      onLoadSuccess={onDocumentLoadSuccess}
      onPassword={async (callback, reason) => {
        //Refer: https://github.com/wojtekmaj/react-pdf/issues/581#issuecomment-654741296
        const ReasonEnum = {
          InitialRequest: 1,
          InvalidPassword: 2,
        };
        function callbackProxy(password) {
          // Cancel button handler
          if (password === null) {
            // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
            setIsPasswordProtected(true);
          } else if (!isPasswordProtected) callback(password);
        }

        switch (reason) {
          case ReasonEnum.InitialRequest: {
            const password = await promptPassword({
              confirmation: "Enter the password to open this PDF file.",
              options: { error: false, type: "password" },
            });
            callbackProxy(password);
            break;
          }
          case ReasonEnum.InvalidPassword: {
            const password = await promptPassword({
              confirmation: "Invalid password, enter correct password",
              options: { error: true, type: "password" },
            });
            callbackProxy(password);
            break;
          }
          default:
            break;
        }
      }}
    >
      {firstPagePreview ? (
        <Page
          renderMode={"canvas"}
          width={width}
          height={height}
          scale={1.5}
          key={`page_${1}`}
          pageNumber={1}
        />
      ) : (
        Array.from(new Array(numPages), (e, i) => i + 1).map((e, i) => {
          return (
            <Page
              renderMode={"canvas"}
              width={width}
              height={height}
              scale={1.5}
              key={`page_${i + 1}`}
              pageNumber={i + 1}
            />
          );
        })
      )}
    </Document>
  );
};

MyCarousel.propTypes = {
  addMore: PropTypes.func, //For adding another file to the existing array.
  removeData: PropTypes.func, //to delete an item from array of files
};
export default MyCarousel;
